@font-face {
  src: url('/webapp-assets/fonts/circular-pro/CircularPro-Bold.woff2') format('woff2'),
    url('/webapp-assets/fonts/circular-pro/CircularPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  font-family: 'Circular Pro';
}

@font-face {
  src: url('/webapp-assets/fonts/circular-pro/CircularPro-Medium.woff2') format('woff2'),
    url('/webapp-assets/fonts/circular-pro/CircularPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-family: 'Circular Pro';
}

@font-face {
  src: url('/webapp-assets/fonts/circular-pro/CircularPro-Light.woff2') format('woff2'),
    url('/webapp-assets/fonts/circular-pro/CircularPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  font-family: 'Circular Pro';
}

@font-face {
  src: url('/webapp-assets/fonts/circular-pro/CircularPro-Book.woff2') format('woff2'),
    url('/webapp-assets/fonts/circular-pro/CircularPro-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-family: 'Circular Pro';
}

@font-face {
  src: url('/webapp-assets/fonts/circular-pro/CircularPro-Book.woff2') format('woff2'),
    url('/webapp-assets/fonts/circular-pro/CircularPro-Book.woff') format('woff');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
  font-family: 'Circular Pro';
}
