/* You can add global styles to this file, and also import other style files */
@import url('webapp-assets/fonts/circular-pro/stylesheet.css');
@import '@mightyhealth/web-components/dist/web-components/web-components.css';
@import 'quill/dist/quill.snow.css';
@import 'swiper/scss';

@tailwind utilities;
/* @tailwind base; */
/* @tailwind components; */

body,
html {
  background-color: var(--color-beige-50);
  padding: 0;
  margin: 0;
}

.padding-content {
  padding-left: 15vw;
  padding-right: 15vw;
}

.spinner-loader {
  width: 20px;
  height: 20px;
  border: 3px solid var(--color-gray-200);
  border-bottom-color: var(--color-primary);
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
}

@media (max-width: 1140px) {
  .padding-content {
    padding-left: 8.33vw;
    padding-right: 8.33vw;
  }
}

@media (max-width: 720px) {
  .padding-content {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media (max-width: 540px) {
  .padding-content {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
}

.content-header {
  border-top: 1px solid #f3f3f5;
  background: var(--color-white);
  padding-top: 20px;
  padding-bottom: 20px;
}

.hide-overflow {
  overflow: hidden !important;
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}

.margin-top {
  margin-top: 10vh;
}

#front-chat-container.hidden {
  display: none;
}

@media (max-width: 960px) {
  .hide-overflow-m {
    overflow: hidden !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.iframe-container .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@keyframes slideAndFade {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(5px);
    opacity: 0;
  }
}

.ql-snow .ql-tooltip {
  left: 150px !important;
}

.bg-beige-50 {
  background-color: var(--color-beige-50);
}

#zmmtg-root {
  display: none;
  min-width: 0 !important;
  z-index: 10000;
}

mighty-button {
  outline-style: initial !important;
}
